import React, { Component } from 'react';
import _ from "lodash";
import auth from "../../services/authService";
import { getDepartmentList } from "../../services/departmentService";
import { getApplicants } from "../../services/applicantsService";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import Pagination from "../Common/pagination/pagination";
import SearchBox from "../Common/searchBox";
import ApplicantsList from './applicantsList';
import FilterByDepartment from "../Common/filters/FilterByDepartment";
import FilterByApplicantStatus from "../Common/filters/FilterByApplicantStatus";
import "./applicants.scss";

class Applicants extends Component {
  mounted = false;
  state = {
    dataLoading: true,
    departments: [],
    applicants: [],
    filterDepartment: [],
    filterStatus: null,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: ['application_date'], order: "desc"},
    searchQuery: "",
    totalCount: 0
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resDepartments } = await getDepartmentList();
    const departments = _.orderBy(resDepartments.department, ['name'], ['asc']);

    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) {
      this.setState({ filterDepartment: [userDepartmentId], currentPage: 1 });
    } else {
      this.setState({ filterDepartment: [], currentPage: 1 });
    }

    this.mounted && this.setState({ departments });
    this.mounted && this.populateApplicants();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateApplicants = async () => {
    try {
      const { data: resApplicants } = await getApplicants({
        DEPARTMENT_ID: this.state.filterDepartment,
        STATUS: this.state.filterStatus,
        LIMIT: this.state.pageSize,
        PAGE: this.state.currentPage,
        ORDER_BY: this.state.sortColumn.path,
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery
      });
      this.setState({
        applicants: _.orderBy(resApplicants.applicants, [this.state.sortColumn.path], [this.state.sortColumn.order]),
        totalCount: resApplicants.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateApplicants();
  };

  handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    await this.setState({ filterDepartment: departmentSelected, currentPage: 1 });
    this.populateApplicants();
  };

  handleStatusChange = async (itemSelected) => {
    const statusSelected = itemSelected.value;
    await this.setState({ filterStatus: statusSelected, currentPage: 1 });
    this.populateApplicants();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateApplicants();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateApplicants();
  };

  render() {
    const {
      dataLoading,
      overLoading,
      departments,
      applicants,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      totalCount,
      totalItemsCount,
      pageWrapperHeight
    } = this.state;
    const { user } = this.props;

    return (
      <main className="applicant-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-solid fa-clipboard"></i>&nbsp;Applicants</h1>
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-right">
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <FilterByDepartment
                  departments={departments}
                  handleDepartmentChange={this.handleDepartmentChange}
                />
                <FilterByApplicantStatus
                  handleStatusChange={this.handleStatusChange}
                />
              </div>
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </div>
            </div>
          </section>
          <ApplicantsList
            applicants={ applicants }
            user={ user }
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onStatus={this.handleOnChangeStatus}
            isLoading={ dataLoading }
            overLoading={ overLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={totalCount}
              totalItemsCount={totalItemsCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              overLoading={overLoading}
            />
          )}
        </section>
      </main>
    );
  }
}

export default Applicants;
