import http from "./httpService";
import { messages } from '../utils/language-en.json';

const apiEndpoint = "/events";

export function getEvents({DEPARTMENT_ID = [], LIMIT = 100, PAGE = 1, ORDER_BY = 'name', SORT_ORDER = 'ASC', SEARCH, STATUS} ) {
  return http.get(apiEndpoint + '/list', { 
    params: {
      department_id: DEPARTMENT_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH,
      status: STATUS
    }
  });
}

export function saveEvent(data) {
  if (data.id) {
    const body = { ...data };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', data);
}

export function deleteEvent(id) {
  return http.post( apiEndpoint + '/delete', { id: id } );
}

export function cloneEvent(id, eventName, eventDates) {
  return http.post( apiEndpoint + '/clone', { 
    id: id,
    name: eventName,
    event_dates: eventDates
  });
}

export function addEventsDates(data) {
  return http.post(apiEndpoint + '/events_dates_add', data);
}

export function validateEventNumber(eventNumber) {
  return http.get( apiEndpoint + '/event_number_validation', { 
    params: {
      event_number: eventNumber 
    }
  });
}

export function getEventsItem(id) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: id 
    }
  });
}

export function getEventNotificationData(token) {
  return http.get(apiEndpoint + '/get_id_notification', { 
    params: {
      token: token 
    }
  });
}

export function getEventPositions(eventId, eventDateId) {
  return http.get(apiEndpoint + '/positions', { 
    params: {
      id: eventId, 
      event_date_id: eventDateId 
    }
  });
}

export function getEventStaffMembers(eventPositionId, departmentId, searchQuery, staffType, notificationStatus) {
  return http.get(apiEndpoint + '/get_available_staff_members', { 
    params: {
      position_event_id: eventPositionId, 
      department_id: departmentId, 
      search: searchQuery || '',
      type: staffType,
      status: notificationStatus
    }
  });
}

export function getEventExpensesList(eventId) {
  return http.get(apiEndpoint + '/events_expenses_list', { 
    params: {
      event_id: eventId 
    }
  });
}

export function addEventExpense(expenseDescription, expenseAmount, eventId, eventName) {
  return http.post(apiEndpoint + '/events_expenses_add', 
  { 
    description: expenseDescription, 
    amount: expenseAmount, 
    event_id: eventId,
    event_name: eventName
  });
}

export function deleteEventExpense(expenseId, expenseDescription, eventName) {
  return http.post(apiEndpoint + '/events_expenses_delete', 
  { 
    id: expenseId ,
    description: expenseDescription, 
    event_name: eventName
  });
}

export function getEventPositionList(eventPositionId) {
  return http.get(apiEndpoint + '/get_assigned_staff', { 
    params: {
      position_event_id: eventPositionId 
    }
  });
}

export function addEventPosition(eventPositionId, eventDateId) {
  return http.post(apiEndpoint + '/positions_add', { position_event_id: eventPositionId, event_date_id: eventDateId } );
}

export function eventPostionUpdate(positionData) {
  return http.post(apiEndpoint + '/positions_update', positionData );
}

export function deleteEventPosition(staffEventId) {
  return http.post(apiEndpoint + '/positions_delete', { staff_event_id: staffEventId } );
}

export function deleteBulkEventPositions(data) {
  return http.post(apiEndpoint + '/positions_bulk_delete', data );
}

export function notificationBulkEventStaff(eventId, staffMembers) {
  return http.post(apiEndpoint + '/staff_bulk_notification', { event_id: eventId, staff: staffMembers } );
}

export function confirmationBulkEventStaff(eventId) {
  return http.post(apiEndpoint + '/staff_bulk_confirmation', { event_id: eventId } );
}

export function cloneEventDatesPositions(data) {
  return http.post(apiEndpoint + '/events_dates_clone', data );
}

export function getEventStaffSummary(eventId, eventDateId) {
  return http.get(apiEndpoint + '/average_hourly_rate', { 
    params: {
      event_id: eventId,
      event_date_id: eventDateId
    }
  });
}

export function getOvertimeStaff(staffId, startDate, endDate) {
  return http.get(apiEndpoint + '/get_overtime_events_staff', { 
    params: {
      staff_id: staffId,
      start_date: startDate,
      end_date: endDate
    }
  });
}

export function getOverlapStaff(staffId, staffEventId, startDate, endDate) {
  return http.get(apiEndpoint + '/get_overlap_events_staff', { 
    params: {
      staff_id: staffId,
      staff_event_id: staffEventId,
      start_date: startDate,
      end_date: endDate
    }
  });
}

export function cleanUnusedPositions(type, eventId, eventDateId) {
  return http.post(apiEndpoint + '/clean_unused_positions', { type: type, event_id: eventId, event_date_id: eventDateId } );
}

export function generateNotificationStatusSheetAll(eventId) {
  return http.post(apiEndpoint + '/generate_notification_sheet_all', { id: eventId } );
}

export function generateNotificationStatusSheetAccepted(eventId) {
  return http.post(apiEndpoint + '/generate_notification_sheet_accepted', { id: eventId } );
}

export function generateSignInSheetBlank(eventId, eventDateId) {
  return http.post(apiEndpoint + '/generate_sign_in_sheet_blank', { id: eventId, event_date_id: eventDateId } );
}

export function generateSignInSheetStaffDay(eventId, eventDateId, stations) {
  return http.post(apiEndpoint + '/generate_sign_in_sheet_day', { id: eventId, event_date_id: eventDateId, stations: stations } );
}

export function generateSignInSheetStaffAll(eventId, stations) {
  return http.post(apiEndpoint + '/generate_sign_in_sheet_all', { id: eventId, stations: stations } );
}

export function generatePositionSheetDay(eventId, eventDateId) {
  return http.post(apiEndpoint + '/generate_position_sheet_day', { id: eventId, event_date_id: eventDateId } );
}

export function generatePositionSheetAll(eventId) {
  return http.post(apiEndpoint + '/generate_position_sheet_all', { id: eventId } );
}

export function generateGroupPositionSheetDay(eventId, eventDateId) {
  return http.post(apiEndpoint + '/generate_position_sheet_group_day', { id: eventId, event_date_id: eventDateId } );
}

export function generateGroupPositionSheetAll(eventId) {
  return http.post(apiEndpoint + '/generate_position_sheet_group_all', { id: eventId } );
}

export function generateSummarySheet(eventId, type) {
  return http.post(apiEndpoint + '/generate_summary_sheet', { id: eventId, type: type } );
}

export function generateExpensesSheet(eventId) {
  return http.post(apiEndpoint + '/generate_expenses_sheet', { id: eventId } );
}

export function saveBulkTime(data) {
  return http.post(apiEndpoint + '/positions_list_bulk_time', {
    position_event_id: data.position_event_id,
    time: data.time,
    type: data.type,
    type_time: data.type_time
  } );
}

export function saveBulkDateTime(data) {
  return http.post(apiEndpoint + '/positions_list_bulk_time', data );
}

export function saveBulkGroup(data) {
  return http.post(apiEndpoint + '/positions_bulk_group', data );
}

export function getEventReconciliationList(data) {
  return http.get(apiEndpoint + '/reconciliation_list', data );
}

export function updateEventReconciliation(data) {
  return http.post(apiEndpoint + '/reconciliation_update', {...data} );
}

export function getEventCheckInList(data) {
  return http.get(apiEndpoint + '/checkin_list', data );
}

export function updateEventCheckIn(data) {
  return http.post(apiEndpoint + '/checkin_update', {...data} );
}

export function updateEventReconciliationBulkEndTimes(data) {
  return http.post(apiEndpoint + '/reconciliation_bulk_time', {...data} );
}

export function updateEventReconciliationBulk(data) {
  return http.post(apiEndpoint + '/reconciliation_bulk', {...data} );
}

export function getEventReport(eventId) {
  return http.get(apiEndpoint + '/get_id_note', { 
    params: {
      id: eventId 
    }
  });
}

export function updateEventReport(data) {
  return http.post(apiEndpoint + '/update_note', data );
}

export function updateEventNotification(id, status, source) {
  return http.post(apiEndpoint + '/update_notification', {
    id: id,
    status: status,
    source: source
  });
}

export function updateEventDateTotals(eventDateId, staffQuantity, volunteersQuantity) {
  return http.post(apiEndpoint + '/update_date_totals', {
    event_date_id: eventDateId,
    staff_qty: staffQuantity,
    volunteers_qty: volunteersQuantity
  });
}

export function getEventSummaryList(eventId) {
  return http.get(apiEndpoint + '/summary_list', {
    params: {
      id: eventId
    }
  });
}

export function getEventsCalendar(month, year, department_id, status) {
  return http.get(apiEndpoint + '/get_events_calendar', { 
    params: {
      month: month,
      year: year,
      department_id: department_id,
      status: status,
    }
  });
}

export function generateEventsCalendar(month, year, department_id, status) {
  return http.post(apiEndpoint + '/generate_events_calendar', 
  { 
    month: month,
    year: year,
    department_id: department_id,
    status: status,
  });
}

export function addConfigurationPosition(data, eventId) {
  data = {event_id: eventId, ...data};
  return http.post(apiEndpoint + '/positions_conf_add', data);
}

// ** MIDDLEWARES
// *************************************************************

export async function fetchEventMiddleware(eventId) {
  try {
    const { data: resEvent } = await getEventsItem(eventId);
    const eventData = resEvent.event;

    const event = {
      ...eventData
    }

    return { data: event, eventDates: event.events_dates };
  } catch (ex) {
    if (ex.response && ex.response.status === 404)
      return null
  }
}

export async function addEventDatesMiddleware(eventData, eventDates, id) {
  for (let i = 0; i < eventDates.length; i++) {
    const element = eventDates[i];
    if (!element.id) {
      const eventDateData = {
        event_id: id,
        venuesconf_id: Number(eventData['venuesconf_id']),
        start_date: element.start_date,
        end_date: element.end_date
      }
      const { data } = await addEventsDates(eventDateData);
      if (!data.success) {
        return { success: false, message: data.message}
      }
    }
  }
  return { success: true }
}

export async function addEventMiddleware(eventData, eventDates) {
  try {
    const { data } = await saveEvent(eventData);
    if (!data.success) {
      return { success: false, message: data.message}
    }
    const response = await addEventDatesMiddleware(eventData, eventDates, data.id)
    if (!response.success) {
      return { success: false, message: response.message }
    }
    return { success: true, id: data.id, message: messages.success.eventUpdated }
  } catch (ex) {
    if (ex.response && ex.response.status === 404)
      return { success: false, message: ex.message}
  }
}

export async function updateEventMiddleware(eventData, eventDates, eventDatesChange) {
  try {
    const { data } = await saveEvent(eventData);
    if (!data.success) {
      return { success: false, message: data.message}
    }
    if (eventDatesChange) {
      const response = await addEventDatesMiddleware(eventData, eventDates, eventData.id)
      if (!response.success) {
        return { success: false, message: response.message }
      }
    }
    return { success: true, message: messages.success.eventUpdated }
  } catch (ex) {
    if (ex.response && ex.response.status === 404)
      return null;
  }
}
