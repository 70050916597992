import React, { useReducer, useEffect, useContext } from 'react';
import { getEventPositionList } from '../../../../../services/eventsService';
import { EventFetchContext } from '../../Event';
import { EventStaffContext } from '../EventStaff';
import EventStaffAssignedList from './EventStaffAssignedList';
import EventStaffAssignedToolsSelect from './EventStaffAssignedTools/EventStaffAssignedToolSelect';
import EventStaffAssignedToolDelete from './EventStaffAssignedTools/EventStaffAssignedToolDelete';
import EventStaffAssignedToolAddPosition from './EventStaffAssignedTools/EventStaffAssignedToolAddPosition';
import EventStaffAssignedToolPrint from './EventStaffAssignedTools/EventStaffAssignedToolPrint';
import EventStaffAssignedToolBulkGroup from './EventStaffAssignedTools/EventStaffAssignedToolBulkGroup';
import EventStaffAssignedToolBulkDate from './EventStaffAssignedTools/EventStaffAssignedToolBulkDate';
import './EventStaffAssigned.scss';

export const EventStaffAssignedContext = React.createContext();

const initialEventStaffAssignedList = {
  loading: true,
  data: null,
  error: null,
  reload: false,
  selected: null
}
const initialEventStaffAssignedEditDate = null;

const reducerEventStaffAssigned = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_STAFF_ASSIGNED_FETCH':
      return {...state, data: action.VALUE}
    case 'EVENT_STAFF_ASSIGNED_FETCH_LOADING':
      return {...state, loading: action.VALUE}
    case 'EVENT_STAFF_ASSIGNED_SELECT':
      return {...state, selected: action.VALUE}
    default:
      return state
  }
};

function EventStaffAssigned() {
  const event = useContext(EventStaffContext).event;
  const eventDate = useContext(EventStaffContext).eventdate;
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const [eventStaffAssignedList, dispatchEventStaffAssignedList] = useReducer(reducerEventStaffAssigned, initialEventStaffAssignedList);
  const [eventStaffAssignedEditTime, dispatchEventStaffAssignedEditTime] = useReducer(reducerEventStaffAssigned, initialEventStaffAssignedEditDate);
  const eventIsClosed = event.status === 'Closed';
  const eventIsReconciled = event.status === 'Reconciled';
  
  const fetchEventStaffAssigned = async () => {
    dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: null });
    dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH_LOADING', VALUE: true });
    try {
      const { data } = await getEventPositionList( eventStaffContext.position.selected.position_event_id );
      const positions = data.positions;
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: positions });
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH_LOADING', VALUE: false });

      // POSITION AVAILABLE
      const eventStaffAssignedAvailable = positions.filter(item => {
        return item.staff_id === 0
      } );
      eventStaffContext.dispatchAvailableAssignedPositions({ 
        TYPE: 'POSITIONS_AVAILABLE', 
        VALUE: { 
          list: eventStaffAssignedAvailable, 
          total: eventStaffAssignedAvailable.length,
          positionsTotal: positions.length
        }
      });

    } catch (ex) { }
  };

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_STAFF_ASSIGNED && fetchEventStaffAssigned();
    eventFetchContext.fetchData.EVENT_STAFF_ASSIGNED && eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED', VALUE: false });;
  }, [eventFetchContext.fetchData.EVENT_STAFF_ASSIGNED]);

  useEffect(() => {
    eventStaffContext.position.selected && fetchEventStaffAssigned();
  }, [
    eventStaffContext.position.selected
  ]);

  // ** HANDLE
  // *********************************************************
  
  const handleSelectAssignedStaff = (staff) => {
    if (!staff) {
      const staffAssignedSelected = eventStaffAssignedList.data.map(item => {
        if (!item.reconciled) 
          return { ...item, rowSelected: !eventStaffAssignedList.selected}
        return {...item}
      });
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: staffAssignedSelected });
      const rowsSelected = staffAssignedSelected.filter(item => item.rowSelected);
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: rowsSelected.length === 0  ? null : rowsSelected });
      return
    }

    const isSelected = eventStaffAssignedList.selected && eventStaffAssignedList.selected.find(item => item.staff_event_id === staff.staff_event_id);

    if (isSelected) {
      const deselect =  eventStaffAssignedList.selected.filter(item => item.staff_event_id !== staff.staff_event_id);

      const updateSelectedList = eventStaffAssignedList.data.map(item => {
        if (item.staff_event_id === staff.staff_event_id) {
          if (item.rowSelected) return { ...item, rowSelected: false }
          return { ...item, rowSelected: true }
        }
        return {...item}
      });
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: deselect.length === 0 ? null : deselect });
      dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: updateSelectedList });
      return
    }

    const newSelectedList = !eventStaffAssignedList.selected ? [staff] : [...eventStaffAssignedList.selected, staff];
    const updateSelectedList = eventStaffAssignedList.data.map(item => {
      if (item.staff_event_id === staff.staff_event_id) return {...item, rowSelected: true}
      return {...item}
    })
    dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: newSelectedList });
    dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: updateSelectedList })
    return
  }

  return (
    <EventStaffAssignedContext.Provider
      value={{
        eventStaffAssignedList, 
        dispatchEventStaffAssignedList,
        handleSelectAssignedStaff,
        eventStaffAssignedEditTime,
        dispatchEventStaffAssignedEditTime
      }}
    >
    <section className="EventStaffAssigned">
      <div className="EventStaffAssigned-toolsbar d-flex align-items-center">
        <h6 className="title">Assigned Staff Members</h6>
        { !eventIsClosed && !eventIsReconciled &&
        <div className="EventStaffAssigned-toolsbar-items">
          <EventStaffAssignedToolsSelect />
          <EventStaffAssignedToolBulkDate />
          <EventStaffAssignedToolBulkGroup />
          <EventStaffAssignedToolDelete />
        </div>
        }
      </div>

      <EventStaffAssignedList />

      <div className="EventStaffAssigned-footer d-flex align-items-center justify-content-between">
        <div className="col-right">
          { !eventIsClosed && !eventIsReconciled && <EventStaffAssignedToolAddPosition /> }
        </div>
        <div className="col-left">
          <EventStaffAssignedToolPrint event={event} eventDate={eventDate}/>
        </div>
      </div>
    </section>
    </EventStaffAssignedContext.Provider>
  )
}

export default EventStaffAssigned
