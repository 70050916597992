import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import { cloneEventDatesPositions } from '../../../../../../services/eventsService';
import { EventFetchContext } from '../../../Event';
import { EventStaffContext } from '../../EventStaff';

function EventStaffAssignedToolClonePosition() {
  const event = useContext(EventStaffContext).event;
  const eventStaffContext = useContext(EventStaffContext);
  const eventFetchContext = useContext(EventFetchContext)
  const [clonePositions, setClonePositions] = useState(false);
  const [dateOptions, setDateOptions] = useState(null);
  const [cloneDateSelected, setCloneDateSelected] = useState(null);
  const eventDate = (eventStaffContext.eventdate) ? moment(eventStaffContext.eventdate.start_date).format('MM/DD/YYYY') : '';
  const [cloneShiftsOriginal, setCloneShiftsOriginal] = useState('');
  const [cloneShiftsSelected, setCloneShiftsSelected] = useState('');

  useEffect(() => {
    const eventsDatesOptionBuild = event.events_dates.map((item, idx) => {
      const label = 'Day ' + (idx+1) + ' – ' + moment(item.start_date).format('MM/DD/YYYY');
      return {
        value: item.id,
        label: label + ' - Shifts: ' + item.staff_total + '/' + item.positions_total
      }
    });

    const eventdates = eventStaffContext.eventdate && eventsDatesOptionBuild.filter(m => m.value !== eventStaffContext.eventdate.id)
    setDateOptions(eventdates);
  }, [eventStaffContext.eventdate]);

  // ** HANDLE
  // *********************************************************

  const handleSelectCloneDate = (selected) => {
    selected = {
      event_id: event.id,
      origin_event_date_id: selected.value,
      destiny_event_date_id:  eventStaffContext.eventdate.id
    }

    setCloneDateSelected(selected);

    const cloneToDate = event.events_dates.filter(d => d.id === selected.destiny_event_date_id);
    setCloneShiftsOriginal(cloneToDate[0].staff_total + '/' + cloneToDate[0].staff_total);

    const cloneFromDate = event.events_dates.filter(d => d.id === selected.origin_event_date_id);
    setCloneShiftsSelected(cloneFromDate[0] ? cloneFromDate[0].staff_total + '/' + cloneFromDate[0].staff_total : 'undefined');
  }

  const handleCloneDate = async () => {
    setClonePositions(false);
    setCloneShiftsOriginal('');
    setCloneShiftsSelected('');
    try {
      const { data } = await cloneEventDatesPositions(cloneDateSelected);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(data.message, { autoClose: 1000 });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
    } catch (error) {}
  }

  const handleCancelClone = () => {
    setClonePositions(false);
    setCloneShiftsOriginal('');
    setCloneShiftsSelected('');
  }

  return (
    <React.Fragment>
      { event.events_dates.length > 1 &&
        <React.Fragment>
          <button className="btn btn-clone-position" type="button"
            onClick={() => setClonePositions(true)}
          >
            <i className="fas fa-users"></i>Clone Positions and Staff
          </button>
        
          <Modal show={clonePositions} onHide={() => setClonePositions(false)} className="modal-clone">

            <Modal.Header>
              <Modal.Title>
                <i className="fas fa-users"></i>&nbsp;
                Clone Positions and Staff
              </Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
              <Select
                className="select-clone-date select-style-default"
                isSearchable={false}
                options={dateOptions}
                placeholder="Select the day you want to clone from"
                onChange={selected => handleSelectCloneDate(selected)}
              />
              <p className="disclaimer">
                Clone action will copy the positions and staff members, including their start/end time, pay rate, 
                and group values.
              </p>
              <p className="warning">This will overwrite the current data <br/>for the selected date <strong>{eventDate}</strong>.</p>

              {cloneShiftsSelected.length > 0 ?
                <p className="warning">You will replace <strong>{cloneShiftsOriginal}</strong> shifts
                  with <strong>{cloneShiftsSelected}</strong> shifts.
                  <br />Do you want to clone?</p>
                : '' }
            </Modal.Body>
    
            <Modal.Footer>
              <Button variant="secondary" onClick={() => handleCancelClone()}>Cancel</Button>
              <Button variant="success" 
                disabled={!cloneDateSelected} 
                onClick={() => handleCloneDate()} 
              >Clone</Button>
            </Modal.Footer>
        
          </Modal>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default EventStaffAssignedToolClonePosition
