import React, { useState, useEffect, useContext } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { messages } from '../../../../../utils/language-en.json';
import { SwipeableViewsContext } from '../../Event';
import EventInfoToolCleanUnused from './EventInfoTools/EventInfoToolCleanUnused';

const EVENT_STATUS = {
  PRE_EVENT: 'Pre-Event',
  INITIATED: 'Initiated',
  FINALIZED: 'Finalized',
  RECONCILED: 'Reconciled',
  CLOSED: 'Closed'
}

function EventInfoDates({ eventDates, dispatchEventDates, eventStatus }) {

  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [addDate, setAddDate] = useState({ start_date: null, end_date: null });
  const [showAddDates, setShowAddDates] = useState(false);

  useEffect(() => {
    eventStatus === EVENT_STATUS.PRE_EVENT && setShowAddDates(true);
    eventStatus === EVENT_STATUS.INITIATED && setShowAddDates(true);
    eventStatus === EVENT_STATUS.FINALIZED && setShowAddDates(true);
  },[eventStatus]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });

  // ** RENDERS 
  // *************************************************************
  const renderTableHeader = () => (
    <tr>
      <th className="day"></th>
      <th className="date">Start</th>
      <th className="date">End</th>
      <th className="date">Staffed Positions</th>
      <th className="date">Volunteers</th>
      { showAddDates && <th className="options"></th> }
    </tr>
  );
  
  const renderEventDatePicker = (name, label, minDate, required = false) => {
    const alertStyleClass = addDate.start_date && addDate.end_date && !validEventDateRange() ? ' btn btn-datepicker has-error ' : 'btn btn-datepicker' ;

    let includeDates = null;
    if (minDate) {
      var tomorrow = new Date(minDate);
      tomorrow.setDate(minDate.getDate() + 1);
      includeDates = [minDate, tomorrow];
    }

    const CustomInput = React.forwardRef((props,ref) => {
      return (
        <button type="button" className={alertStyleClass}
          onClick={props.onClick} 
          ref={ref}>
          {props.value || props.placeholder} &nbsp;<span className="fas fa-calendar-day"></span>
        </button>
      )
    });

    return (
      <div className="form-group">
        <ReactDatePicker
          fixedHeight
          calendarClassName="event-datepicker"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MM/dd/yyyy – HH:mm"
          timeCaption="Time"
          selected={addDate[name]}
          includeDates={includeDates}
          openToDate={minDate}
          name={name}
          label={label}
          required={required}
          onChange={date => setAddDate({...addDate, [name]: date })}
          minDate={minDate}
          customInput={<CustomInput />}
          popperPlacement="left"
        />
      </div>
    )
  }

  const renderAddDates = () => {
    return (
      <tr className="EventInfoDates-add">
        <td className="day"></td>
        <td className="date">{renderEventDatePicker("start_date", "Start Date & Time", null)}</td>
        <td className="date">{renderEventDatePicker("end_date", "End Date & Time", addDate.start_date)}</td>
        { showAddDates && 
          <td className="options">
            <button type="button" className="btn btn-color-primary btn-add"
              disabled={!validEventDateRange()}
              onClick={() => handleAddDate()}
            >
              <i className="fa fa-plus"></i> 
            </button>
          </td>
        }
      </tr>
    )
  };

  const renderDatesList = () => {
    const list = eventDates.map((date, idx) => (
      <tr className="event-date-item" key={idx}>
        <td className="day">
          <i className="far fa-clock"></i>&nbsp;Day&nbsp;{idx+1}
        </td>
        <td className="date">
          {date.start_date}
        </td>
        <td className="date">
          {date.end_date}
        </td>
        <td className="positions d-flex justify-content-center">
          <div className="numbers">
            {(date.staff_total) ? date.staff_total : 0}/
            {(date.positions_total) ? date.positions_total : 0}
          </div>
          <div className="clean">
            { date.id &&
              <EventInfoToolCleanUnused eventDate={date} />
            }
          </div>
        </td>
        <td className="date">
          {(date.volunteers_total) ? date.volunteers_total : 0}
        </td>
        { showAddDates && 
          <td className="options">
            { !date.id &&
              <i className="fa fa-trash" 
                onClick={() => handleDeleteDate(idx)}
              ></i>
            }
          </td>
        }
      </tr>
    ));

    return list
  };

  const renderRangeError = () => (
    <tr>
      <td className="border-none"></td>
      <td colSpan="2" className="border-none EventInfoDates-range-alert">
        { messages.error.dateOutOfRange }
      </td>
    </tr>
  )


  // ** HANDLE
  // *************************************************************
  const handleAddDate = () => {
    var startDateShift = moment(addDate.start_date);
    var endDateShift = moment(addDate.end_date);
    var durationShift = endDateShift.diff(startDateShift, 'minutes');

    if (durationShift > 1440) {
      toast.error(messages.error.dateShiftLonger);
    } else {
      const EventDates = [
        ...eventDates, 
        {
          start_date: moment(addDate.start_date).format('MM/DD/YYYY HH:mm'),
          end_date: moment(addDate.end_date).format('MM/DD/YYYY HH:mm'),
          staff_total: '-',
          positions_total: '-',
          volunteers_total: '-'
        } 
      ];
      const sortEventDates =  _.orderBy(EventDates, ['start_date'], ['asc']);
      dispatchEventDates(sortEventDates);
      setAddDate({ start_date: null, end_date: null });
    }
  };

  const handleDeleteDate = (idx) => {
    const eventDatesList = eventDates.filter((item, index) => index !== idx);
    dispatchEventDates(eventDatesList);
  };

  // ** VALIDATIONS
  // *************************************************************

  const validEventDateRange = () => {
    const { start_date, end_date } = addDate;
    if (end_date <= start_date) return false;
    return true;
  };

  return (
    <section className='EventInfoDates'>
      <h3 className="EventInfoDates-label"><i className="far fa-clock"></i>&nbsp;Events Days</h3>
      <table className="EventInfoDates-list table">
        <thead>
          { showAddDates && renderAddDates() }
          { renderTableHeader() }
        </thead>
        <tbody>
          { addDate.start_date && addDate.end_date && !validEventDateRange() && renderRangeError() }
          { renderDatesList() }
        </tbody>
      </table>
    </section>
  )
}

export default EventInfoDates
