import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import { EventStaffContext } from '../../EventStaff';
import { confirmationBulkEventStaff } from '../../../../../../services/eventsService';
import { messages } from '../../../../../../utils/language-en.json';
import { EventFetchContext } from '../../../Event';

function EventStaffAssignedToolConfirmations() {
  const event = useContext(EventStaffContext).event;
  const eventFetchContext = useContext(EventFetchContext);
  const [pendingConfirmations, setPendingConfirmations] = useState(false);

  // ** HANDLE
  // *********************************************************

  const handleSendConfirmations = async () => {
    setPendingConfirmations(false)
    try {
      const { data } = await confirmationBulkEventStaff(event.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      } else {
        toast.success(messages.success.confirmationSent, { autoClose: 3000 });
      }
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
    } catch (error) {}
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <button className="btn btn-clone-position" type="button"
          disabled={event.pending_confirmations === '0'}
          onClick={() => setPendingConfirmations(true)}
        >
          <i className="fas fa-inbox"></i>Pending Confirmations ({event.pending_confirmations})
        </button>
      
        <Modal show={pendingConfirmations} onHide={() => setPendingConfirmations(false)} className="modal-notification">

          <Modal.Header>
            <Modal.Title>
              <i className="fas fa-inbox"></i>&nbsp;
              Pending Confirmations
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            {(event.test_mode_notifications) ?
              <div className="testing-mode-container">
                <h4>TEST MODE</h4>
                <p>Mails to {event.email_testing} <br />
                SMS to {event.phone_testing}</p>
              </div> : ''}
            <p><b>
              This event has {event.pending_confirmations} 
              {(event.pending_confirmations === '1') ? ' confirmation ' : ' confirmations '} 
              ready to be sent to the assigned members.</b>
            </p>
            <p>Do you want to proceed?</p>
          </Modal.Body>
  
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPendingConfirmations(false)}>Cancel</Button>
            <Button variant="success" onClick={() => handleSendConfirmations()} >Yes</Button>
          </Modal.Footer>
      
        </Modal>
      </React.Fragment>
    </React.Fragment>
  )
}

export default EventStaffAssignedToolConfirmations
