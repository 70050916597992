import React, { useState, useEffect, useReducer, useContext  } from 'react'
import { toast } from 'react-toastify';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Select from 'react-select';
import Scrollbars from 'tt-react-custom-scrollbars/lib/Scrollbars';
import { getDepartmentList } from '../../../../../services/departmentService';
import { addVolunteer, getAllOrganizations } from '../../../../../services/volunteersService';
import { getEventStaffMembers, eventPostionUpdate, updateEventDateTotals, notificationBulkEventStaff } from '../../../../../services/eventsService';
import VolunteersAddModalForm from '../../../../Volunteers/volunteers-forms/partials/VolunteersAddModal';
import EmptyData from '../../../../Common/emptydata/emptyData';
import Loading from '../../../../Common/loading/loading';
import { messages } from '../../../../../utils/language-en.json';
import { EventFetchContext } from '../../Event';
import { EventStaffContext } from '../EventStaff';
import EventStaffAvailableList from './EventStaffAvailableList';
import EventStaffAvailableListNotification from './EventStaffAvailableListNotification';
import EventStaffAvailableToolNotification from './EventStaffAvailableTools/EventStaffAvailableToolNotification';
import { UserPermissions } from '../../../../../utils/UserPermissions';
import './EventStaffAvailable.scss';

export const EventStaffAvailableContext = React.createContext();

const initialDepartment = {
  selected: null,
  options: null
}
const initalStaffAvailable = {
  loading: false,
  data: null,
  selected: null
};
const initialStaffAvailableSearch = '';
const initialStaffType = 'Staff';
const initialNotificationStatus = 'All';

const reducerEventStaffAvailable = (state, action) => {
  switch (action.TYPE) {
    case 'DEPARTMENTS_FETCH':
      return {...state, options: action.VALUE}
      case 'DEPARTMENT_SELECT':
        let selected = action.VALUE
        return {...state, selected}
      case 'STAFF_AVAILABLE_LOADING':
        return { ...state, loading: action.VALUE }
      case 'STAFF_AVAILABLE_FETCH':
        return { ...state, data: action.VALUE}
      case 'STAFF_AVAILABLE_SELECTED':
        return { ...state, selected: action.VALUE }
      case 'STAFF_AVAILABLE_SEARCH':
        return action.VALUE
    default:
      return state
  }
}

function EventStaffAvailable() {
  const event = useContext(EventStaffContext).event;
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const [department, dispatchDepartment] = useReducer(reducerEventStaffAvailable, initialDepartment);
  const [staffAvailable, dispatchStaffAvailable] = useReducer(reducerEventStaffAvailable, initalStaffAvailable);
  const [staffAvailableSearch, dispatchStaffAvailableSearch] = useReducer(reducerEventStaffAvailable, initialStaffAvailableSearch);
  const [staffType, dispatchStaffType] = useReducer(reducerEventStaffAvailable, initialStaffType);
  const [notificationStatus, dispatchNotificationStatus] = useReducer(reducerEventStaffAvailable, initialNotificationStatus);
  const [showAddVolunteerModal, setShowAddVolunteerModal] = useState(false);
  const [volunteerItem, setVolunteerItem] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [tabsContent, setTabsContent] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [notifyAll, setNotifyAll] = useState(false);
  const [staffNotify, setStaffNotify] = useState([]);
  const [staffNotifyCounter, setStaffNotifyCounter] = useState(0);
  const [testModeNotifications, setTestModeNotifications] = useState(false);
  const [emailTesting, setEmailTesting] = useState('');
  const [phoneTesting, setPhoneTesting] = useState('');

  const typeOptions =  [
    { value: 'Staff', label: 'Staff' },
    { value: 'Volunteers', label: 'Volunteers' },
    { value: 'All', label: 'All' }
  ];

  const statusOptions =  [
    { value: 'All', label: 'All' },
    { value: 'Not Notified', label: 'Not Notified' },
    { value: 'Notified', label: 'Notified' },
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Rejected', label: 'Rejected' }
  ];

  const handleAddVolunteer = () => {
    setShowAddVolunteerModal(true);
  };

  const handleAddVolunteerClose = () => {
    setShowAddVolunteerModal(false);
    setVolunteerItem([]);
  };

  const handleChangeAddVolunteerForm = ({ currentTarget: input }) => {
    let value = input.value
    const data = { ...volunteerItem };
    data[input.name] = value;
    setVolunteerItem(data);
  }

  const handleSaveAddVolunteer = async () => {
    try {
      const { data } = await addVolunteer(volunteerItem.first_name, volunteerItem.last_name, volunteerItem.organization_id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.volunteerCreated, { autoClose: 1000 });
      setShowAddVolunteerModal(false);
      setVolunteerItem([]);
      fetchStaffAvailable();
    } catch (ex) {
    }
    return; 
  }

  useEffect(() => {
    const fetchDepartment = async () => {
      const { data } = await getDepartmentList();
      dispatchDepartment({TYPE: 'DEPARTMENTS_FETCH', VALUE: data.department });
      const initialDepartment = data.department.filter(department => department.id === event.department_id);
      !department.selected && dispatchDepartment({ TYPE: 'DEPARTMENT_SELECT',  VALUE: initialDepartment[0] });
    }
    !department.options && fetchDepartment();
  }, [department.options]);

  const fetchOrganizations = async () => {
    const { data: resOrganizations } = await getAllOrganizations();
    const organizations = [{ id: '', name: '' }, ...resOrganizations.organizations];
    setOrganizationList(organizations);
  }

  useEffect(() => {
    fetchOrganizations();
  },[]);

  const setTabChange = async (value) => {
    setTabIndex(value);
    dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: null })
  }

  const fetchStaffAvailable = async () => {
    dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_LOADING', VALUE: true });
    const { data } = await getEventStaffMembers( 
      eventStaffContext.position.selected.position_event_id,
      department.selected.id, 
      staffAvailableSearch,
      staffType,
      notificationStatus
    );
    dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_FETCH', VALUE: data.positions });
    dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_LOADING', VALUE: false }); 
    dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: null });
    setStaffNotify(data.notify_staff);
    setStaffNotifyCounter(data.notify_all_counter);
    setTestModeNotifications(data.test_mode_notifications);
    setEmailTesting(data.email_testing);
    setPhoneTesting(data.phone_testing);
  }

  useEffect(() => {
    department.selected && eventStaffContext.position.selected && fetchStaffAvailable();
  }, [
    eventStaffContext.position.selected,
    department.selected,
    staffAvailableSearch,
    staffType,
    notificationStatus
  ]);

  const EventStaffGeneralContent = {
    label: 'General',
    content: idx => (
      <Scrollbars style={{height: 250}} key={idx}>
        <EventStaffAvailableList key={idx} event={event} />
      </Scrollbars>
    )
  };

  const EventStaffNotificationContent = {
    label: "Notification Details",
    content: idx => (
      <Scrollbars style={{height: 250}} key={idx}>
        <EventStaffAvailableListNotification key={idx} event={event} />
      </Scrollbars>
    )
  }

  const eventAvailableStaffContentBuilder = () => {
    let eventAvailableStaffContent = [];
    eventAvailableStaffContent.push(EventStaffGeneralContent);
    UserPermissions('module_events_sub_tab_notification') && eventAvailableStaffContent.push(EventStaffNotificationContent);
    setTabsContent(eventAvailableStaffContent);
  }

  useEffect(() => {
    eventAvailableStaffContentBuilder();
  }, []);

  // ** HANDLERS
  // *********************************************************
  
  const handleAssignMember = async () => {
    const positionsToSave = staffAvailable.selected.map((item, idx) => {
      return {
        ...item, 
        staff_event_id: eventStaffContext.availableAssignedPositions.list[idx].staff_event_id,
        event_id: event.id,
        event_name: event.name,
        event_date_id: eventStaffContext.eventdate.id
      }
    });

    try {
      const staffQuantity = positionsToSave.filter(staff => staff.type === 'Staff').length;
      const volunteersQuantity = positionsToSave.filter(staff => staff.type === 'Volunteer').length;

      const { data } = await updateEventDateTotals(eventStaffContext.eventdate.id, staffQuantity, volunteersQuantity);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data;
        toast.error(errorMessage);
        return
      }

      positionsToSave.map(async (item) => {
        const { data } = await eventPostionUpdate(item);
        const success = data.success;
        if (!success) {
          const errorMessage = data.data;
          toast.error(errorMessage);
          return
        }
      });

      dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: null })
      setTimeout(() => {
        toast.success('Positions Updated', { autoClose: 1000 });

        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_CHECKIN', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      }, 200);
    } catch (error) { }
  }

  const handleNotifyMember = async () => {
    try {
      let staffMembers = '';
      if (notifyAll) {
        staffMembers = staffNotify;
      } else {
        staffAvailable.selected.forEach((staff) => {
          staffMembers += staff.staff_id + ',';
        });
      }

      const { data } = await notificationBulkEventStaff(event.id, staffMembers.slice(0, -1));
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      } else {
        toast.success(messages.success.notificationSent, { autoClose: 3000 });
      }

      dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: null })
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
      setUpdateModalIsOpen(false);
    } catch (ex) {
    }
  }

  const openModalNotification = (type) => {
    if (type === 'All') {
      setNotifyAll(true);
    } else {
      setNotifyAll(false);
    }
    setUpdateModalIsOpen(true);
  }

  const closeModalNotification = () => {
    setUpdateModalIsOpen(false);
  }

  return (
    <EventStaffAvailableContext.Provider
      value={{
        staffAvailable,
        dispatchStaffAvailable
      }}
    >
    <section className="EventStaffAvailable">
      <div className="EventStaffAvailable-header d-flex align-items-center">
        <h3 className="title">Available Staff and Volunteers</h3>
        <Select
          className="select-department"
          options={department.options || []}
          getOptionLabel ={(option) => option.name}
          getOptionValue ={(option) => option.id}
          value={department.selected}
          onChange={optionSelected => dispatchDepartment({ TYPE: 'DEPARTMENT_SELECT', VALUE: optionSelected }) }
        />
      </div>
      <div className="EventStaffAvailable-filters">
        <div className="input-group EventStaffAvailable-search">
          <div className={ (UserPermissions('module_events_sub_tab_notification')) 
              ? 'EventStaffAvailable-filter' 
              : 'EventStaffAvailable-filter-wide' }
          >
            <input
              type="text"
              name="query"
              className="form-control"
              placeholder="Filter by Name"
              onChange={e => dispatchStaffAvailableSearch({ TYPE: 'STAFF_AVAILABLE_SEARCH', VALUE: e.currentTarget.value}) }
            />
          </div>
          <div className="EventStaffAvailable-type">
            <Select
                name="selectType"
                placeholder="Filter by Staff type"
                options={typeOptions}
                className="basic"
                classNamePrefix="select"
                defaultValue={ typeOptions[0] }
                onChange={optionSelected => dispatchStaffType({ TYPE: 'STAFF_AVAILABLE_SEARCH', VALUE: optionSelected.value}) }
              />
            </div>

            { UserPermissions('module_events_sub_tab_notification') &&
              <div className="EventStaffAvailable-status">
                <Select
                  name="selectStatus"
                  placeholder="Filter by Status"
                  options={statusOptions}
                  className="basic"
                  classNamePrefix="select"
                  defaultValue={ statusOptions[0] }
                  onChange={optionSelected => dispatchNotificationStatus({ TYPE: 'STAFF_AVAILABLE_SEARCH', VALUE: optionSelected.value}) }
                />
              </div>
            }

            <div className="EventStaffAvailable-add">
              <button type="button" className="btn btn-color-primary-outline btn-sm" onClick={() => handleAddVolunteer()}>
                <b>+</b>
              </button>
            </div>
            <VolunteersAddModalForm 
              isOpen={showAddVolunteerModal}
              onRequestClose={handleAddVolunteerClose}
              closeModal={handleAddVolunteerClose}
              handleChange={handleChangeAddVolunteerForm}
              organizationOptions={organizationList}
              handleSave={handleSaveAddVolunteer}
              data={volunteerItem}
            />
        </div>
      </div>
      <div className="EventStaffAvailable-list">
        { department.selected && department.selected.id !== event.department_id  &&
          <div className="alert-share-resource ">
            <p className="d-flex align-items-center">
              <i className="fas fa-exclamation-triangle"></i><span>&nbsp;This is a Shared Resource list from <b>{department.selected.name}</b></span>
            </p>
          </div>
        }

        <Loading active={staffAvailable.loading} type="staffMembers"/>
        { (staffAvailable.data && staffAvailable.data.length === 0)
          ? !staffAvailable.loading && <EmptyData type={"staff-members"} message={'No Staff Members found'} />
          : !staffAvailable.loading &&
          <>
            <Tabs 
              value={tabIndex}  
              onChange={(e, value) => setTabChange(value)}
              classes={{ root: 'cms-tabs-sm', indicator: 'cms-tabs-indicator-sm' }}
            >
              {tabsContent && tabsContent.map((form, idx) => (
                <Tab key={idx} label={form.label} classes={{ root: 'cms-tab-sm', selected: 'cms-tab-selected-sm' }} />
              ))}
            </Tabs>
            <SwipeableViews 
              index={tabIndex}
              onChangeIndex={tabIndex => setTabIndex(tabIndex)}
              animateHeight={true}
              disableLazyLoading={true}
              animateTransitions={true}
              >
              {tabsContent && tabsContent.map((form, idx) => (
                form.content(idx)
              ))}
            </SwipeableViews>
          </>
        }
      </div>
      <div className="EventStaffAvailable-footer">
        <p className="text-center">        
          { (tabIndex === 0)
            ?
            <button className="btn btn-primary" 
              disabled={!staffAvailable.selected || staffAvailable.selected.length === 0} 
              onClick={() => handleAssignMember()}
            >
              { !staffAvailable.selected && 'Add Members to staff' }
              { staffAvailable.selected && staffAvailable.selected.length === 0  && 'Add Members to staff' }
              { staffAvailable.selected && staffAvailable.selected.length !== 0 && 'Assign Staff Members (' + staffAvailable.selected.length + ')' }
            </button>
          :
            <>
              <button className="btn btn-primary" 
                disabled={!staffAvailable.selected || staffAvailable.selected.length === 0} 
                onClick={() => openModalNotification('')}
              >
                { !staffAvailable.selected && 'Notify Staff Members' }
                { staffAvailable.selected && staffAvailable.selected.length === 0  && 'Notify Staff Members' }
                { staffAvailable.selected && staffAvailable.selected.length !== 0 && 'Notify Staff Members (' + staffAvailable.selected.length + ')' }
              </button>&nbsp;
              <button className="btn btn-primary" 
              onClick={() => openModalNotification('All')}
            > Notify All
            </button>
            </>
          }
        </p>
        <EventStaffAvailableToolNotification
          isOpen={updateModalIsOpen}
          onRequestClose={closeModalNotification}
          closeModal={closeModalNotification}
          numberItems={(staffAvailable.selected) ? staffAvailable.selected.length : staffNotifyCounter}
          onNotify={handleNotifyMember}
          testModeNotifications={testModeNotifications}
          emailTesting={emailTesting}
          phoneTesting={phoneTesting}
        />
      </div>
    </section>
    </EventStaffAvailableContext.Provider>
  )
}

export default EventStaffAvailable
