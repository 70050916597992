import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "../../../Common/form/editorToolbar";
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-toastify";
import { messages } from '../../../../utils/language-en.json';
import { updateEventReport } from '../../../../services/eventsService';
import './EventReport.scss';

class EventReport extends Component {
  state = {  }

  event = this.props.event;

  componentWillMount() {
    this.populateEventReport();
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  populateEventReport = async () => {
    try {
      const eventReportNote = this.event.note;
      this.setState({ eventReportNote });
    } catch (ex) { }
  }

  handleSaveEventReport = async () => {
    const dataToUpdate = {
      id: this.event.id,
      note: this.state.eventReportNote
    }
    try {
      const { data } = await updateEventReport(dataToUpdate);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage, { autoClose: 1000 });
        return
      }
      toast.success(messages.success.eventReportUpdated, { autoClose: 1000 });
    } catch (ex) { }
  }

  onEditorChange = ( value ) => {
    this.setState( {
      eventReportNote: value
    } );
  }

  render() {
    return (
      <section className="EventReport form-wrapper container-fluid">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={this.state.eventReportNote}
          onChange={this.onEditorChange}
          modules={modules}
          formats={formats}
        />

        <div className="row justify-content-end">
          <div className="col-sm-6 text-right">
            <button className="btn btn-primary mt-3"
              type="button"
              disabled={!this.state.eventReportNote}
              onClick={() => this.handleSaveEventReport()}
            >
              Save Event Report
            </button>
          </div>
        </div>
      </section>
    );
  }
}

EventReport.contextTypes = {
  swipeableViews: PropTypes.object.isRequired
};

export default EventReport;
